import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Content from './Content'
import MyContext from './Context'

import Slider from 'infinite-react-carousel'

import './PageHeader.css'

const settings = {
  arrows: false,
  // autoplay: true,
  // autoplaySpeed: 5000,
  // centerPadding: 10,
  dots: true,
  duration: 400,
  shift: 10
}

const _renderHome = (swiperData, className) => {
  return (
    <MyContext.Consumer>
      {cons => {
        if (!cons) {
          var cons = {
            strings: e => e,
            i18n: {
              language: 'ar'
            }
          }
        }
        return (
          <Slider {...settings}>
            {swiperData.map((item, index) => {
              return (
                <div
                  className={`PageHeader relative HomeHeader ${className}`}
                  key={index}
                >
                  {item.image && (
                    <Image
                      background
                      resolutions="large"
                      src={item.image}
                      alt={'Swiper image'}
                      size="cover"
                    />
                  )}
                </div>
              )
            })}
          </Slider>
        )
      }}
    </MyContext.Consumer>
  )
}

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = '',
  swiperData,
  fromHome
}) => {
  if (large) className += ' PageHeader-large'

  if (fromHome) return _renderHome(swiperData, className)
  else
    return (
      <div
        className={`PageHeader relative ${className}`}
        // style={{ borderRadius: '5px' }}
      >
        {backgroundImage && (
          <Image
            background
            resolutions="large"
            src={backgroundImage}
            alt={title}
            size="contain"
          />
        )}
        <div className="container relative" style={{}}>
          <h1 className="PageHeader--Title" style={{ width: 'max-content' }}>
            {title}
          </h1>
          {subtitle && (
            <Content className="PageHeader--Subtitle" src={subtitle} />
          )}
        </div>
      </div>
    )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
